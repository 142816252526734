@tailwind base;
@tailwind components;

@import 'utilities/rem';
@import 'utilities/font-face';
@import 'utilities/responsive';

@import "components/lazy-load";
@import "components/links";
@import "components/buttons";
@import "components/calendars";

@import "base";

@tailwind utilities;
