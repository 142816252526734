// Font Face
@include font-face('VeronaSerial', 'verona_serial-light-webfont', 300, 'normal');
@include font-face('VeronaSerial', 'verona_serial-regular-webfont', 400, 'normal');
@include font-face('VeronaSerial', 'verona_serial-medium-webfont', 500, 'normal');
@include font-face('VeronaSerial', 'verona_serial-bold-webfont', 700, 'normal');
@include font-face('VeronaSerial', 'verona_serial-xbold-webfont', 800, 'normal');

// Body
html {
   @apply text-base;
}

body {
   transition: background-color 0.8s ease;
   -webkit-font-smoothing: subpixel-antialiased;
   -webkit-text-stroke:1px transparent;
   @apply
      font-sans-primary
      text-gray-800
      text-optimize-legibility
      antialiased;
}

.page-block {
   &--container {
      @apply mx-auto
      px-6;
      @include mobilefirst($sm) {
         padding: 0 4vw;
      }
      @include mobilefirst($lg) {
         padding: 0 7.2vw;
      }
      @include mobilefirst($xl) {
         padding: 0 8.4vw;
         @apply max-w-screen-3xl;
      }
   }
}

// Links

a {
   @apply transition;
}

button:disabled {
   opacity: .4;
   cursor: not-allowed;
}

// Typography

h1, .h1 {
   @apply text-6xl font-serif-primary;
   line-height: 1.05 !important;
   @include responsive($md) {
      @apply text-4xl;
   }
}

h2, .h2, h3, .h3 {
   @apply text-4xl font-serif-primary;
   line-height: 1.25 !important;
   @include responsive($sm) {
      @apply text-3xl;
   }
}

h4, .h4 {
   @apply text-2xl font-serif-primary;
   line-height: 1.25 !important;
   @include responsive($sm) {
      @apply text-xl;
   }
}

h5, .h5 {
   @apply text-xl font-serif-primary;
   line-height: 1.25 !important;
   @include responsive($sm) {
      @apply text-lg;
   }
}

.text-columns {
   h2, .h2 {
      @apply text-xl;
   }
}

p {
   font-weight: 300;
   @apply mb-4;
   &:empty {
      @apply hidden;
   }
   a {
      @apply underline;
   }
   strong {
      font-weight: 800;
   }
}

label {
   @apply text-xs md:text-sm;
}

.formatted-text {
   h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,p {
      @apply mb-4;
   }
   ul {
      @apply list-disc
      pl-10;
   }
}

// Selection

::selection {
   @apply
      text-gray-500
      bg-gray-100;
}

// FOUT fix

.wf-loading {
   @apply invisible;
}

.wf-active,
.wf-inactive {
   @apply visible;
}

// Animations

@keyframes flashAnimation {
   0%, 100% { @apply bg-red-200 }
   50% { @apply bg-transparent; }
}

@keyframes jumpRotate {
   0%, 100% {
     transform: translateY(0) rotate(0deg);
   }
   25% {
     transform: translateY(-20px) rotate(10deg);
   }
   50% {
     transform: translateY(0) rotate(0deg);
   }
   75% {
     transform: translateY(-10px) rotate(-10deg);
   }
 }
 
 .jumping {
   animation: jumpRotate 1.5s infinite ease-in-out;
 } 

 @keyframes dotAnimation {
   0%, 20% {
     opacity: 0;
   }
   40% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
 }
 
 .ellipsis {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 5px;
 }
 
 .ellipsis span {
   width: 10px;
   height: 10px;
   background-color: black;
   border-radius: 50%;
   animation: dotAnimation 1.5s infinite ease-in-out;
 }
 
 .ellipsis span:nth-child(1) {
   animation-delay: 0s;
 }
 
 .ellipsis span:nth-child(2) {
   animation-delay: 0.2s;
 }
 
 .ellipsis span:nth-child(3) {
   animation-delay: 0.4s;
 } 

// Layout

.calendar-slots {
   .calendar-availability {
      @apply hidden;
   }
   &.show-slots {
      .availability-message {
         @apply hidden;
      }
      .calendar-availability {
         @apply grid;
      }
   }
}

.modal {
   display: none;
   position: fixed;
   z-index: 50;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgba(0,0,0,0.4);
}

.modal-container {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
}

.modal-content {
   @apply bg-white
   rounded-md
   mx-auto;
}

.close-button {
   color: #aaa;
   float: right;
   font-size: 28px;
   font-weight: bold;
}

.close-button:hover,
.close-button:focus {
   color: black;
   text-decoration: none;
   cursor: pointer;
}

.modal-buttons {
   margin-top: 15px;
   text-align: right;
}

.bg-gradient-mesh {
   background-color:hsla(0,0%,100%,1);
   background-image:
   radial-gradient(at 42% 36%, hsla(24,69%,78%,1) 0px, transparent 50%),
   radial-gradient(at 93% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
   radial-gradient(at 86% 86%, hsla(339,64%,85%,1) 0px, transparent 50%),
   radial-gradient(at 12% 4%, hsla(42,77%,83%,1) 0px, transparent 50%),
   radial-gradient(at 12% 85%, hsla(169,61%,68%,1) 0px, transparent 50%);
}

// Header

.header--transparent {
   @apply absolute
      top-0
      w-full
      z-50;
}

.rounded-border {
   border-radius: 0 0 50% 50% / 0 0 30% 30%;
}

// Components
.cancel-options {
   @apply flex flex-col gap-4;
}

.cancel-options .field {
   @apply flex flex-col gap-2;
}

.cancel-options .select select {
   @apply appearance-none w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 shadow-sm text-gray-700 text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400;
}

.cancel-options legend {
   @apply text-gray-800 font-semibold mb-2;
}

.cancel-options .heading {
   @apply hidden;
}

.update-method-form .stripe-payment-elements-submit-button {
   @apply transition
   font-semibold
   inline-flex
   items-center
   rounded-full
   justify-center
   flex-shrink-0
   focus:outline-none
   focus:shadow-outline
   text-sm border-b-4
   border-r-4
   border-l-2
   border-t-2
   border-black
   py-2 px-4 
   bg-hl_yellow 
   text-black
   w-full 
   mt-4
   text-center;
}

// TMS Calendar Overrides

.fc-toolbar-title {
   font-size: 1rem !important;
}

#calendar-tms {
   max-height: 550px;
}

.td.fc-timeline-slot-lane {
   @apply bg-gray-100;
}

.fc-timeline-event {
   @apply bg-white p-0 border-0;
}

.fc-event-past .tms-cal-event {
   @apply opacity-50;
}

.fc-event.flashing-event .tms-cal-event {
   animation: flashAnimation 1s infinite;
 }

.tms-cal-event {
   @apply bg-blue-400 bg-opacity-30 text-black rounded-lg border-t border-r border-b border-l-4 border-black;
}