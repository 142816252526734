#calendar-select {
  :root {
    --fc-border-color: #fff;
    --fc-today-bg-color: #2c3e50;
  }
  .fc-toolbar-title {
    font-size: 1.4rem;
    font-weight: 600;
    @apply font-sans;
  }

  .fc-daygrid-day {
    &:after {
      @apply bg-red-200 block rounded-full;
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5px;
      left: 5px;
    }
  }

  .has-slots {
    position: relative;
    cursor: pointer;
    &:hover {
      @apply bg-gray-200;
    }
    &:after {
      @apply bg-mint;
    }
  }

  .no-slots {
    position: relative;
    cursor: pointer;
    &:hover {
      @apply bg-gray-200;
    }
    &:after {
      @apply bg-red-300;
    }
  }

  .fc-daygrid-day-events {
    display: none;
  }
}